<template>
  <div class="game-screen">
    <div id="gamescreen">
      <component
        :is="isLandscape ? 'TitleBarLandscapeSvg' : 'TitleBarSvg'"
        class="titlebar"
        :targetProp="target"
      />
    </div>

    <div
      :class="isLandscape ? 'iframe-container-landscape' : 'iframe-container'"
      id="iframe-container"
    >
      <iframe
        sandbox="allow-scripts allow-same-origin"
        class="actual-iframe"
        id="actual-iframe"
        :src="gameHostUrl"
        ref="iframe-container"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TitleBarSvg from "./components/TitleBarSvg.vue";
import TitleBarLandscapeSvg from "./components/TitleBarLandscapeSvg.vue";
import GameEvent from "./AppEvents.js";
import { Constants } from "./constants.js";

export default {
  data() {
    return {
      redirected: false,
      gameContent: "", // Store the fetched content here
      gameSessionId: "",
      challengeId: this.challenge,
      target: this.targetProp,
      currentScore: {},
      result: {},
      isGameStart: false,
      attemptsLeft: 0,
    };
  },
  name: "GameScreen",
  components: {
    TitleBarSvg,
    TitleBarLandscapeSvg,
  },
  props: {
    gameHostUrl: {
      type: String,
      required: true,
    },
    challenge: {
      type: String,
      required: true,
    },
    targetProp: {
      type: String,
      required: true,
    },
    isLandscape: {
      type: Boolean,
      required: true,
    },
  },
  async mounted() {
    // const iframe = document.getElementById("iframe-container");
    const iframe = this.$refs["iframe-container"];
    this.emitIsLandscape();
    if (iframe) {
      const conf = window.__frolic_engage;
      //   const payload = {
      //     challengeId: this.challengeId,
      //     userId: conf.conf.user.userId,
      //   };
      console.log(conf);
      const res = await axios.get(
        `${location.origin}/ext/fynd-engage/application/engage/v1.0/game/frolicGames/gameCampaign/${this.challengeId}`
      );
      const session = res?.data?.payload?.session;
      const gameDetails = res?.data?.payload?.campaigns?.gameDetails;
      if (res.data.outOfAttempts) {
        // this.attemptsLeft = 0;
        const dataPayload = {
          score: res.data.latestScore,
          isOutOfAttempts: true,
          sessionId: res.data._id,
        };
        this.resultScreen(dataPayload);
      }
      this.attemptsLeft = session?.attemptsLeft;
      const GAME_DATA = {
        apiData: {
          match_making_data: {
            game_mode_type: Constants.GAME_MODE_TYPE,
            attempt_id: session?.id || "",
            game_server_data: {
              server_info: "NA",
              server_type: Constants.FROLIC,
            },
            session_id: session?.id || "",
            contest_id: this.challengeId || "",
            game_id: gameDetails?.id || "",
          },
        },

        playerData: {
          avatar:
            "https://hdn-1.fynd.com/company/884/applications/000000000000000000000001/theme/pictures/free/original/default-profile_nxhzui.png",
          id: "64b0e72ff39fa44c7a753d90",
          username: "Player",
        },
      };
      console.log(GAME_DATA);
      Object.freeze(GAME_DATA);
      const gameWindow = iframe.contentWindow;
      console.log(gameWindow);

      this.gameEvent = new GameEvent(
        GAME_DATA,
        gameWindow,
        session?.id,
        this.resultScreen.bind(this)
      );
      this.gameEvent.subscribe();
    }
  },

  methods: {
    resultScreen(data) {
      console.log("result screen: ", data);
      if (this.attemptsLeft !== 0) {
        this.attemptsLeft - 1;
      }
      data["attemptsLeft"] = this.attemptsLeft;
      this.$emit("on-result-screen-load", data);
    },
    emitIsLandscape() {
      this.$emit("is-Landscape", this.isLandscape);
    },
  },
};
</script>

<style scoped>
.titlebar {
  flex: 20%;
  width: 100%;
  height: 100%;
}
.image-png {
  position: relative;
  top: -0.5rem;
  width: 100%;
  height: 100%;
}
.iframe-container {
  height: 588px;
  width: -webkit-fill-available;
}
.iframe-container-landscape {
  flex: 80%;
}
.actual-iframe {
  width: -webkit-fill-available;
  height: 100%;
}
.game-screen {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

/* Mobile screens (320px to 480px) */
@media screen and (min-width: 320px) and (max-width: 480px) {
  .iframe-container {
    height: 600px;
  }
  .titlebar {
    flex: 20%;
  }
  .iframe-container-landscape {
    flex: 80%;
  }
}

/* Laptop and Tablet screens (481px to 1024px) */
@media screen and (min-width: 481px) and (max-width: 1024px) {
  .titlebar {
    flex: 20%;
  }
  .iframe-container-landscape {
    flex: 80%;
  }
}

/* Large desktops (1024px to 1919px) */
@media screen and (min-width: 1024px) and (max-width: 1919px) {
  .titlebar {
    flex: 20%;
  }
  .iframe-container-landscape {
    flex: 80%;
  }
}
</style>
