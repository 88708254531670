import Vue from "vue";
import BaseWidget from "../BaseWidget.js";
import App from "./App.vue";
import style from "./style.js";

const {
  base_url: iframeSrc,
  application_id: applicationId,
  company_id: companyId,
  user,
} = window.__frolic_engage?.conf;

console.log("Base URL:", iframeSrc);
console.log("Application ID:", applicationId);
console.log("Company ID:", companyId);
console.log("User Info:", user);

const ORIENTATIONS = {
  LANDSCAPE: "Landscape",
  PORTRAIT: "Portrait",
};

// Create a new Vue instance
let app;

class FrolicGames extends BaseWidget {
  constructor(widgetConfig) {
    super();
    this.widgetConfig = widgetConfig;
    this.isRender = false;
    this.state = "closed";
    this.openCount = 0;
    this.softHide = false;
    this.orientation = ORIENTATIONS.PORTRAIT;
    this.iframe = null; // Explicitly track the iframe instance

    // Listen for the event to open the widget
    window.addEventListener("play-frolic-games", () =>
      this.handlePlayFrolicGames()
    );
  }

  handlePlayFrolicGames() {
    console.log("Received 'play-frolic-games' event");
    if (this.state === "closed") {
      this.init(() => this.show());
    }
  }

  getIframe() {
    if (!this.iframe) {
      console.log("Creating new iframe");
      this.iframe = document.createElement("iframe");
      this.iframe.id = "frolic-iframe";
      this.iframe.src = `${iframeSrc}/frolic-games`;
      this.iframe.allow = "clipboard-read; clipboard-write; autoplay";
    }
    return this.iframe;
  }

  getDiv() {
    if (!this.div) {
      this.div = document.createElement("div");
    }
    return this.div;
  }

  getinnerDiv() {
    if (!this.innerDiv) {
      this.innerDiv = document.createElement("div");
    }
    return this.innerDiv;
  }

  getBaseElement() {
    if (!this.baseelement) {
      this.baseelement = document.getElementsByClassName("baseview");
    }
    return this.baseelement;
  }

  show() {
    console.log("Showing Frolic Games widget");
    const div = this.getDiv();
    const innerDiv = this.getinnerDiv();
    const iframe = this.getIframe();

    console.log(iframe);

    // Create a new iframe instance if it's null
    if (!this.iframe) {
      this.iframe = document.createElement("iframe");
      this.iframe.id = "frolic-iframe";
      this.iframe.src = `${iframeSrc}/frolic-games`;
      this.iframe.allow = "clipboard-read; clipboard-write; autoplay";
      innerDiv.appendChild(this.iframe); // Append the iframe to innerDiv
    }

    // Reset classes for visibility
    div.className = "slot-machine-box-open_game_settings";
    innerDiv.className = "inner-div";
    innerDiv.id = "frolic-game-widget_";

    // Reinitialize Vue app if destroyed
    if (!app) {
      app = new Vue({
        render: (h) => h(App),
      });
      app.$mount("#frolic-game-widget_");
    }

    // Adjust parent container styles based on orientation
    if (this.orientation === ORIENTATIONS.PORTRAIT) {
      const closedGameParentDiv = document.getElementsByClassName("frolic-app");
      if (closedGameParentDiv.length) {
        closedGameParentDiv[0].style.display = "flex";
      }
    } else {
      const closedGameParentDiv = document.getElementsByClassName(
        "frolic-app-landscape"
      );
      if (closedGameParentDiv.length) {
        closedGameParentDiv[0].style.display = "flex";
      }
    }

    // Set state to open
    this.state = "open";

    // Show the cross element
    const crossElement = document.getElementById(
      "logo-show-cross_game_settings"
    );
    if (crossElement) {
      crossElement.style.display = "initial";
    }
  }

  hide() {
    console.log("Hiding Frolic Games widget");

    // Check if the widget is in landscape mode
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;

    if (isLandscape) {
      console.log("Switching layout to portrait mode before hiding...");

      // Change landscape divs to portrait
      const closedGameParentDivLandscape = document.getElementsByClassName(
        "frolic-app-landscape"
      );
      const maindiv = document.getElementsByClassName(
        "frolicgames-container-landscape"
      );

      if (closedGameParentDivLandscape.length) {
        for (let div of closedGameParentDivLandscape) {
          if (maindiv.length) {
            for (let mainDiv of maindiv) {
              mainDiv.classList.remove("frolicgames-container-landscape");
              mainDiv.classList.add("frolicgames-container"); // Switch to portrait class
            }
          }

          div.classList.remove("frolic-app-landscape");
          div.classList.add("frolic-app"); // Add portrait class
          div.style.display = "none"; // Ensure it's hidden
        }
      }

      console.log("Layout switched to portrait mode successfully.");
    }

    // Emit the close-frolic-game event directly here
    const closeEvent = new CustomEvent("close-frolic-game", {
      detail: {
        timestamp: Date.now(),
      },
    });
    window.dispatchEvent(closeEvent);
    console.log("close-frolic-game event emitted.");

    const div = this.getDiv();
    const innerDiv = this.getinnerDiv();
    const baseelement = this.getBaseElement();

    // Update classes to hide elements
    div.className = "slot-machine-box-closed_game_settings";
    innerDiv.className = "contents-div-closed_game_settings";
    this.state = "closed";

    // Reset base element opacity
    if (baseelement.length > 0) {
      baseelement[0].style.opacity = 1;
    }

    // Hide parent containers for portrait mode
    const closedGameParentDiv = document.getElementsByClassName("frolic-app");
    if (closedGameParentDiv.length) {
      for (let div of closedGameParentDiv) {
        div.style.display = "none"; // Ensure portrait divs are hidden
      }
    }

    // Hide the cross element
    const crossElement = document.getElementById(
      "logo-show-cross_game_settings"
    );
    if (crossElement) {
      crossElement.style.display = "none";
    }

    console.log("Widget hidden successfully with portrait adjustments.");
  }

  init(callback) {
    console.log("Initializing Frolic Games widget");
    if (!this.isRender) {
      this.render(document.body, document.head, callback);
    } else {
      callback(this);
    }
  }

  gameGetWidgetComponent() {
    const stylesheet = document.createElement("style");
    stylesheet.textContent = style;

    const maindiv = document.createElement("div");
    maindiv.className = "main-div_game_settings";

    const div = this.getDiv();
    div.className = "container-div_game_settings";

    let innerDiv = this.getinnerDiv();

    // Cross button
    const logoDivCross = document.createElement("div");
    logoDivCross.className = "logo-div-cross-closed_game_settings";
    const imgCross = document.createElement("img");
    imgCross.src =
      "https://cdn.pixelbin.io/v2/frolic/original/__playground/close.png";
    imgCross.id = "logo-show-cross_game_settings";
    imgCross.classList.add("portrait-cross");
    imgCross.addEventListener("click", () => this.hide());
    logoDivCross.append(imgCross);

    return {
      stylesheet,
      innerDiv,
      logoDivCross,
      div,
    };
  }

  render(bodyElement, headElement, callback) {
    const { stylesheet, innerDiv, logoDivCross, div } =
      this.gameGetWidgetComponent();
    headElement.append(stylesheet);

    if (!div.querySelector(".contents-div-closed_game_settings")) {
      div.append(innerDiv);
    }
    if (!div.querySelector(".logo-div-cross-closed_game_settings")) {
      div.append(logoDivCross);
    }

    bodyElement.append(div);

    this.isRender = true;

    callback(this);
  }
}

const frolicGames = new FrolicGames(
  window.__frolic_engage.getWidgetConfig("frolic-games")
);
window.__frolic_engage.registerWidget("frolic-games", frolicGames);

// Add event listener for "play-frolic-games" event
window.addEventListener("play-frolic-games", () =>
  frolicGames.handlePlayFrolicGames()
);

// Add message event listeners
window.addEventListener("message", frolicGames.handlePostmessage);
window.addEventListener("message", frolicGames.handleGameOpen);
